<template>
  <LiefengContent>
    <template v-slot:title>推文列表</template>
    <template v-slot:toolsbarRight>
      <Input
      :maxlength="20"
        v-model.trim="selectTitle"
        placeholder="请输入标题"
        style="width: 150px;margin-right:10px"
      ></Input>
     <Select v-model="tweetType" style="width:150px" placeholder="请选择推文类型">
        <Option v-for="item in tweetTypeList" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
    </Select>
      <Button type="primary" icon="ios-search" @click="search">搜索</Button>
      <Button type="success" icon="md-refresh" @click="reset">重置</Button>
      <Button
        @click="exportExcel"
        type="warning"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
      <Button
        type="error"
        icon="ios-arrow-back"
        @click="$router.push('/officialmanage?menuId=' + $route.query.menuId)"
        >返回</Button
      >
    </template>
    <template v-slot:contentArea>
      <div class="table-left">
        <Menu theme="light" :active-name="activeMenu" @on-select="selectMenu">
          <MenuGroup title="信息状态">
            <MenuItem name="0"> 待提交({{ status0 }}) </MenuItem>
            <MenuItem name="1"> 待审核({{ status1 }}) </MenuItem>
            <MenuItem name="2"> 待发布({{ status2 }}) </MenuItem>
            <MenuItem name="3"> 已发布({{ status3 }}) </MenuItem>
            <MenuItem name="4"> 已归档({{ status4 }}) </MenuItem>
            <MenuItem name="6"> 全部信息 </MenuItem>
          </MenuGroup>
        </Menu>
      </div>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="currentPage"
        @hadlePageSize="pageChange"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
      ></LiefengTable>
      <!-- 查看弹窗 -->
      <LiefengModal
        title="查看推文"
        :value="seeTweetStatus"
        :fullscreen="true"
        @input="seeTweetFn"
      >
        <template v-slot:contentarea>
          <SeeTweet :infoId="infoId" v-if="seeTweetStatus"></SeeTweet>
        </template>
      </LiefengModal>
      <!-- 发布推文 -->
      <LiefengModal
        title="发布推文"
        :value="publishTweetStatus"
        :fullscreen="true"
        @input="publishTweetFn"
      >
        <template v-slot:contentarea>
          <SeeTweet :infoId="infoId" v-if="publishTweetStatus"></SeeTweet>
        </template>
        <template v-slot:toolsbar>
            <Button type="info" @click="publishTweetFn(false)" style="margin-right: 10px">取消</Button>
            <Button type="primary" @click="publish" style="margin-right: 10px">发布</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route('/officialtweet')
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import SeeTweet from "@/views/official/childrens/seeTweet";
import { format } from "@/utils/formatTime";
export default {
  data() {
    return {
      //发送状态
      sendStatus: "",
      //推文类型
      tweetType: "",
      tweetTypeList: [],
      //搜索标题
      selectTitle: "",
      //查看弹窗
      seeTweetStatus: false,
      publishTweetStatus: false,
      seeFormData: {
        title: "", //标题
        source: "", //信息来源
        subtype: "", //信息类型
        appointmentPhone: "", //联系电话（预约号码）
        startTime: "", //开始时间
        subStartTime: "",
        endTime: "", //结束时间
        subEndTime: "",
        provinceCode: 44, //省编码
        cityCode: "", //城市编码
        areaCode: "", //区县编码
        streetCode: "", //街道编码
        projectCodeList: "", //社区编码
        city: "", //修改时地址
        content: "", //富文本
        photo: "", //封面图片上传路径
        settingImgPath: "", //高级设置背景图片

        voteTypeList: [], //投票方式列表

        //规则配置
        questionBankId: "", // 题库类型
        userVerified: "0", //实名认证 0 不需要 1 需要
        period: "", //周期设置 全程0 每天 24 小时 0-24 ,
        answerNumber: "", //答题局数 ,
        roundQuestionNumber: "", //每局题目数
        questionLimit: "", //每题限时 s
        participantsNumber: "", //参与人数
        winnersNumber: "", //中奖人数
        awardMethod: "", //评奖方式
        effectiveTime: "", //有效领奖开始时间 ,
        expirationTime: "", //有效领奖截止时间

        valiStartTime: "", //每天答题起始时间 格式00:00:00 ,
        valiEndTime: "", // 每天答题截止时间 格式00:00:00 ,
        whetherComment: "0", //是否开启评论 0 否 1 是 ,

        modifier: parent.vue.loginInfo.userinfo.nickName, //修改人
        staffId: parent.vue.loginInfo.userinfo.id,
        status: 1,
        creatorId: parent.vue.loginInfo.userinfo.id,
      },
      loading: false,
      infoId: "", // row id
      //表头列表
      talbeColumns: [
        {
          title: "数据编码",
          key: "code",
          minWidth: 130,
          align: "center",
        },
        {
          title: "推文类型",
          key: "type",
          minWidth: 130,
          align: "center",
        },
        {
          title: "标题",
          key: "title",
          minWidth: 130,
          align: "center",
        },
        {
          title: "状态",
          key: "status",
          minWidth: 130,
          align: "center",
        },
        {
          title: "发送状态",
          key: "sendStatus",
          minWidth: 130,
          align: "center",
        },
        {
          title: "创建人",
          key: "createName",
          minWidth: 130,
          align: "center",
        },
        {
          title: "创建时间",
          key: "createTime",
          minWidth: 130,
          align: "center",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 180,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  style: {
                    marginRight: "10px",
                  },
                  on: {
                    click: () => {
                      this.infoId = params.row.id;
                      this.seeTweetFn(true);
                    },
                  },
                },
                "查看"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "success",
                    size: "small",
                    disabled: params.row.status == '待发布' ? false : true
                  },
                  on: {
                    click: () => {
                      this.infoId = params.row.id;
                      this.publishTweetFn(true);
                    },
                  },
                },
                "发布"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 20,
      menuId: "",
      type: "",
      columnCode: "",
      status: "0",
      //信息状态
      status1: 0,
      status2: 0,
      status3: 0,
      status4: 0,
      status0: 0,
      //活跃menu
      activeMenu: "",
    };
  },
  methods: {
    //发布
    publish() {
      this.$post('/voteapi/api/pc/aggre/application/updatePoseStatus',{
        status: '3',
        id: this.infoId,
        staffId: parent.vue.loginInfo.userinfo.id
      }).then(res => {
        if(res.code == 200) {
          this.$Message.success({
            background: true,
            content: "发布成功"
          });
          this.pageChange({
            page: this.currentPage,
            pageSize: this.pageSize,
          })
          this.publishTweetFn(false);
        }else {
           this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      })
    },
    //搜索
    search() {
      this.pageChange({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    //重置
    reset() {
      this.selectTitle = "";
      this.sendStatus = "";
      this.tweetType = "";
      this.currentPage = 1;
      this.pageSize = 20;
      this.pageChange({
        page: this.currentPage,
        pageSize: this.pageSize,
      });
    },
    //查看弹窗
    seeTweetFn(status) {
      this.seeTweetStatus = status;
    },
    //发布弹窗
    publishTweetFn(status) {
      this.publishTweetStatus = status;
    },  
  
    //左侧信息菜单
    selectMenu(name) {
      if (name == "6") {
        this.status = "";
      } else {
        this.status = name;
      }
      this.pageChange({
        page: this.currentPage,
        pageSize: this.pageSize,
      });
    },
    pageChange(obj) {
      this.loading = true;
      this.$get(
        "/voteapi/api/pc/aggre/application/selectAggreApplicationPose",
        {
          applicationCode: this.$route.query.id,
          staffId: parent.vue.loginInfo.userinfo.id,
          type: this.tweetType,
          title: this.selectTitle,
          status: this.status,
          sendStatus: this.sendStatus,
          page: obj.page,
          pageSize: obj.pageSize,
        }
      )
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            this.total = res.maxCount;
            this.currentPage = res.currentPage;
            this.pageSize = res.pageSize;

            //修改res数据格式
            res.dataList.map((item, index, arr) => {
              arr[index].createTime = format(
                item.createTime,
                "yyyy-MM-dd HH:mm:ss"
              );
              //转换状态
              switch (item.status) {
                case "0":
                  arr[index].status = "待提交";
                  break;
                case "1":
                  arr[index].status = "待审核";
                  break;
                case "2":
                  arr[index].status = "待发布";
                  break;
                case "3":
                  arr[index].status = "已发布";
                  break;
                case "4":
                  arr[index].status = "已归档";
                  break;
              }
              //转换发送状态
              switch (item.sendStatus) {
                case "END_SUCCESS":
                  item.sendStatus = "发送成功";
                  break;
                case "SENDING":
                  item.sendStatus = "发送中";
                  break;
                case "SEND_FAIL":
                  item.sendStatus = "发送失败";
                  break;
                case "DELETE":
                  item.sendStatus = "已删除";
                  break;
                case "WAIT_SEND":
                  item.sendStatus = "等待发送";
                  break;
              }
              this.tweetTypeList.map(subitem => {
                if(item.type == subitem.dictKey) {
                  item.type = subitem.dictValue
                }
              })
            });
            this.tableData = res.dataList;
            this.messageStatusCount();
            this.loading = false;
          } else {
            this.$Message.error({
              background: true,
              content: "获取数据失败",
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "数据编码",
            "推文类型",
            "标题",
            "状态",
            "发送状态",
            "创建人",
            "创建时间",
          ];
          let filterVal = [
            "code",
            "type",
            "title",
            "status",
            "sendStatus",
            "createName",
            "createTime",
          ];
          let tableData = JSON.parse(JSON.stringify(this.tableData));
          this.$core.exportExcel(tHeader, filterVal, tableData, "推文列表");
        },
      });
    },
    messageStatusCount() {
      //获取信息状态的数量
      this.$get("/voteapi/api/pc/aggre/application/selectAggreApplicationPoseByAllStatusCount", {
        staffId: parent.vue.loginInfo.userinfo.id,
        applicationCode: this.$route.query.id,
      }).then((res) => {
        if (res.code == 200) {
          res.dataList.map(item => {
            switch (item.status) {
              case '0':
                this.status0 = item.count;
                break;
              case '1':
                this.status1 = item.count;
                break;
              case '2':
                this.status2 = item.count;
                break;
              case '3':
                this.status3 = item.count;
                break;
              case '4':
                this.status4 = item.count;
                break;
            }
          })
        }
      });
    },
  },

  async created() {
    let userButtonRoot = parent.vue.userButtonRoot;
    //选中第一项菜单
    this.activeMenu = this.status;
    this.menuId = this.$core.getUrlParam("menuId") || "";
    //获取查询类型
    await this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "AGGRE_APPLICATION_POSE_TYPE",
    }).then((res) => {
      if (res.code == 200 && res.dataList) {
        this.tweetTypeList = res.dataList;
      } else {
        this.$Message.error({
          background: true,
          content: "获取推文类型失败",
        });
      }
    });
    this.pageChange({
      page: 1,
      pageSize: this.pageSize,
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    SeeTweet,
  },
};
</script>
    
<style scoped lang='less'>
.table-left {
  float: left;
  width: 160px;
  /deep/.ivu-menu {
    width: 160px !important;
  }
}
</style>